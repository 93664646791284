import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import isNil from 'lodash/isNil';

export class CouponTrackingModel {
  standard: CouponTrackingData;
  pro: CouponTrackingData;

  constructor(props: Partial<CouponTrackingModel> = {}) {
    Object.assign(this, props);
    this.standard = new CouponTrackingData(props.standard);
    this.pro = new CouponTrackingData(props.pro);
  }
}

export class CouponTrackingData {
  included: number;
  used: number;
  offerIds: Guid[];

  get usageDisplay() {
    if ((this.included || 0) <= 0 && (this.used || 0) <= 0) {
      return 'None included';
    }
    return `${(this.used || 0).toLocaleString()} of ${(
      this.included || 0
    ).toLocaleString()}`;
  }

  get usagePercent() {
    if (isNil(this.included)) return 0;

    const used = this.used || 0;
    return (used / this.included) * 100;
  }

  constructor(props: Partial<CouponTrackingData> = {}) {
    Object.assign(this, props);
    this.offerIds = mapArray(props.offerIds, (x) => Guid.valueOrNew(x));
  }
}
