import { OptionModel } from '@komo-tech/core/models/OptionModel';

import { PriceFeatures } from './PriceFeatures';
import {
  PriceFeatureTypes,
  PriceFeatureVariableTypes
} from './PriceFeatureTypes';

export class PriceFeautureDefinition {
  feature: PriceFeatures;
  variableType: PriceFeatureVariableTypes;
  type: PriceFeatureTypes;
  name: string;
  description: string;
  constructor(props?: Partial<PriceFeautureDefinition>) {
    props = props || {};
    Object.assign(this, props);
  }

  toOptionModel(): OptionModel {
    return {
      label: this.name,
      value: this.feature
    };
  }
}
