import { Guid } from '@komo-tech/core/models/Guid';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { BillingFeatureTypeDefinition } from '../../../features/shared/BillingFeatureTypeDefinition';
import { BillingFeatureTypes } from '../../../features/shared/BillingFeatureTypes';
import { BillingSubscriptionModifierProperties } from '../shared/BillingSubscriptionModifierProperties';
import { BillingSubscriptionModifierTypes } from '../shared/BillingSubscriptionModifierTypes';

export class AdminBillingSubscriptionModifier {
  id: Guid;
  billingSubscriptionId: Guid;
  type: BillingSubscriptionModifierTypes;
  featureType: BillingFeatureTypes;
  featureDefinition: BillingFeatureTypeDefinition;
  value?: number;
  updatedAt: Date;
  properties: BillingSubscriptionModifierProperties;

  constructor(props?: Partial<AdminBillingSubscriptionModifier>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.billingSubscriptionId = Guid.valueOrUndefined(
      props.billingSubscriptionId
    );
    this.featureDefinition = new BillingFeatureTypeDefinition(
      props.featureDefinition
    );
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new BillingSubscriptionModifierProperties(
      props.properties
    );
  }

  get isUnlimited() {
    return this.value === null;
  }

  get valueDisplay() {
    if (this.type === BillingSubscriptionModifierTypes.Enable) {
      return 'Yes';
    }

    if (this.type === BillingSubscriptionModifierTypes.Disable) {
      return 'No';
    }

    if (this.type === BillingSubscriptionModifierTypes.AddQuantity) {
      if (this.isUnlimited) {
        return 'Unlimited';
      }

      return this.value?.toLocaleString();
    }

    return '-';
  }
}
