import { Guid } from '@komo-tech/core/models/Guid';

import { AdministratorRoles } from './AdministratorRoles';

export class AdministratorRole {
  companyId: Guid;
  role: AdministratorRoles = AdministratorRoles.Viewer;

  constructor(props?: Partial<AdministratorRole>) {
    props = props || {};
    Object.assign(this, props);
    this.companyId = Guid.valueOrEmpty(props.companyId);
  }
}
