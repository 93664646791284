import { Guid } from '@komo-tech/core/models/Guid';
import { MinMax } from '@komo-tech/core/models/MinMax';
import { asNumber, toAudCurrencyDisplay } from '@komo-tech/core/utils/number';

export class PricePlanPriceMatrix {
  komoCredits: PriceMatrixItem[];
  hubCredits: PriceMatrixItem[];
  constructor(props?: Partial<PricePlanPriceMatrix>) {
    props = props || {};
    Object.assign(this, props);
    this.komoCredits = (props.komoCredits || []).map(
      (x) => new PriceMatrixItem(x)
    );
    this.hubCredits = (props.hubCredits || []).map(
      (x) => new PriceMatrixItem(x)
    );
  }
}

export class PriceMatrixItem {
  id: Guid;
  minCredits: number;
  maxCredits: number;
  modifierPercent: number;
  constructor(props?: Partial<PriceMatrixItem>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.minCredits = asNumber(props.minCredits);
    this.maxCredits = asNumber(props.maxCredits);
    this.modifierPercent = asNumber(props.modifierPercent);
  }

  getMinMax(): MinMax {
    return { min: this.minCredits, max: this.maxCredits };
  }

  getPercentDisplay() {
    const prefix = this.modifierPercent > 0 ? '+' : '';
    return `${prefix}${this.modifierPercent.toFixed(2)}%`;
  }

  getRangeDisplay() {
    if (!this.minCredits || !this.maxCredits) return '-';
    if (this.minCredits > this.maxCredits) return '-';

    if (this.minCredits === this.maxCredits) {
      const moreThan1 = this.minCredits > 1;
      return `${Math.trunc(this.minCredits)} credit${moreThan1 ? 's' : ''}`;
    }
    return `${Math.trunc(this.minCredits)} - ${Math.trunc(
      this.maxCredits
    )} credits`;
  }

  getDisplayWithPercent() {
    const range = this.getRangeDisplay();
    if (range === '-') return this.getPercentDisplay();
    return `${range} [${this.getPercentDisplay()}]`;
  }

  getNewValueDisplay(baseValue: number) {
    try {
      return toAudCurrencyDisplay(baseValue * (1 + this.modifierPercent / 100));
    } catch {
      return toAudCurrencyDisplay(baseValue);
    }
  }
}
