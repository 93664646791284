import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AdminAdministratorService } from '@/admin/data/AdminAdministratorService';
import { Administrator } from '@/admin/models/Administrator';

export const useAdministratorGet = (
  id: Guid,
  options: Partial<UseQueryOptions<Administrator>> = {}
) =>
  useQuery(
    AdminAdministratorService.cacheKeys.admin(id),
    () => AdminAdministratorService.getAdministratorAsync(id),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
