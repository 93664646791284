import { Guid } from '@komo-tech/core/models/Guid';
import { tryReplaceItemInArray } from '@komo-tech/core/utils/array';
import { utcDateOrNow, utcFormatDate } from '@komo-tech/core/utils/date';
import sortBy from 'lodash/sortBy';

import { PricePlanStatuses } from '../pricing/PricePlanStatuses';
import { CompanyPriceCredit } from './CompanyPriceCredit';
import { CompanyPriceCreditGroup } from './CompanyPriceCreditGroup';

export class CompanyPlan {
  id: Guid;
  pricePlanId: Guid;
  pricePlanName: string;
  pricePlanDescription: string;
  pricePlanStatus: PricePlanStatuses;
  updatedAt: Date;
  pricePlanUpdatedAt: Date;
  hubs: CompanyInnerPlanHubGroup[];
  unGroupedFlexiCredits: CompanyPriceCredit[];

  get planDisplayName() {
    if (this.pricePlanStatus === PricePlanStatuses.Grandfathered) {
      return `Legacy ${this.pricePlanName} [${utcFormatDate(
        this.pricePlanUpdatedAt
      )}]`;
    }
    return this.pricePlanName;
  }

  constructor(props?: Partial<CompanyPlan>) {
    props = props || {};
    Object.assign(this, props);

    this.id = Guid.valueOrNew(props.id);
    this.pricePlanId = Guid.valueOrEmpty(props.pricePlanId);
    this.hubs = (props.hubs || []).map((x) => new CompanyInnerPlanHubGroup(x));
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.pricePlanUpdatedAt = utcDateOrNow(props.pricePlanUpdatedAt);
    this.unGroupedFlexiCredits = (props.unGroupedFlexiCredits || []).map(
      (x) => new CompanyPriceCredit(x)
    );
    this.unGroupedFlexiCredits = (props.unGroupedFlexiCredits || []).map(
      (x) => new CompanyPriceCredit(x)
    );
  }

  getAllCredits() {
    const credits: CompanyPriceCredit[] = [];
    this.unGroupedFlexiCredits.forEach((x) =>
      credits.push(new CompanyPriceCredit(x))
    );
    this.hubs.forEach((h) => {
      h.campaigns.forEach((g) => {
        g.credits.forEach((c) => {
          credits.push(new CompanyPriceCredit(c));
        });
      });
      if (h.unUsed?.credits?.length) {
        h.unUsed.credits.forEach((c) => {
          credits.push(new CompanyPriceCredit(c));
        });
      }
    });

    return credits;
  }

  getAvailableFlexiCredits() {
    const available: CompanyPriceCredit[] = [];

    this.unGroupedFlexiCredits.forEach((x) =>
      available.push(new CompanyPriceCredit(x))
    );
    return available;
  }

  getAvailableFlexiCreditsCount() {
    return this.getAvailableFlexiCredits().length;
  }

  getAssignedNotExpiredHubCredits() {
    const creditGroups: HubCreditGroupDto[] = [];

    this.hubs.forEach((h) => {
      const hubCredits: CompanyPriceCredit[] = [];

      h.campaigns.forEach((g) => {
        g.credits
          .filter((x) => !x.isExpired)
          .forEach((c) => hubCredits.push(new CompanyPriceCredit(c)));
      });

      if (h.unUsed) {
        h.unUsed.credits
          .filter((x) => !x.isExpired)
          .forEach((c) => hubCredits.push(new CompanyPriceCredit(c)));
      }

      if (hubCredits.length) {
        creditGroups.push(
          new HubCreditGroupDto({
            siteId: h.siteId,
            siteName: h.siteName,
            siteDomain: h.siteDomain,
            credits: hubCredits
          })
        );
      }
    });
    return creditGroups;
  }
}

export class CompanyInnerPlanHubGroup {
  siteId: Guid;
  siteName: string;
  siteDomain: string;
  campaigns: CompanyPriceCreditGroup[];
  unUsed?: CompanyPriceCreditGroup;

  get hasCredits() {
    return this.campaigns.length ? true : false;
  }

  constructor(props?: Partial<CompanyInnerPlanHubGroup>) {
    props = props || {};
    Object.assign(this, props);
    this.siteId = Guid.valueOrNew(props.siteId);
    this.campaigns = (props.campaigns || []).map(
      (x) => new CompanyPriceCreditGroup(x)
    );
    if (props.unUsed) {
      this.unUsed = new CompanyPriceCreditGroup(props.unUsed);
    }
  }

  addCampaign(campaign: CompanyPriceCreditGroup) {
    this.campaigns.push(campaign);
    this.sanitiseCampaigns();
  }

  sanitiseCampaigns() {
    if (!this.campaigns.length) return;
    this.campaigns.forEach((c) => {
      c.sanitiseCreditDates();
    });
    this.campaigns = sortBy(this.campaigns, (x) => x.startsAt);
  }

  tryReplaceCampaign(campaign: CompanyPriceCreditGroup) {
    tryReplaceItemInArray(
      this.campaigns,
      (x) => x.id.equals(campaign.id),
      campaign
    );
  }

  tryAssignUnUsedCredits(creditsAssigned: CompanyPriceCredit[]) {
    if (!this.unUsed || !creditsAssigned.length) return;

    this.unUsed.credits = this.unUsed.credits.filter(
      (u) => !creditsAssigned.some((c) => c.id.equals(u.id))
    );
  }
}

export class HubCreditGroupDto {
  siteId: Guid;
  siteName: string;
  siteDomain: string;
  credits: CompanyPriceCredit[];

  constructor(props?: Partial<HubCreditGroupDto>) {
    props = props || {};
    Object.assign(this, props);
    this.siteId = Guid.valueOrEmpty(props.siteId);
    this.credits = (props.credits || []).map((x) => new CompanyPriceCredit(x));
  }
}
