import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrNow, utcDateOrUndefined } from '@komo-tech/core/utils/date';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isNil from 'lodash/isNil';

import { BillingFeatureTypes } from '@/common/models/billing/features/shared/BillingFeatureTypes';
import { AdminBillingPlan } from '@/common/models/billing/plans/Admin/AdminBillingPlan';
import { BillingSubscriptionStatuses } from '@/common/models/billing/subscriptions/shared/BillingSubscriptionStatuses';

import { AdminBillingSubscriptionModifier } from '../modifiers/Admin/AdminBillingSubscriptionModifier';
import { BillingSubscriptionProperties } from '../shared/BillingSubscriptionProperties';

export class AdminBillingSubscription {
  id: Guid;
  companyId: Guid;
  billingPlanId: Guid;
  plan: AdminBillingPlan;
  modifiers: AdminBillingSubscriptionModifier[];
  isActive: boolean;
  startedAt: Date;
  endedAt?: Date;
  timeZoneId: string;
  updatedAt: Date;
  properties: BillingSubscriptionProperties;

  constructor(props?: Partial<AdminBillingSubscription>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.companyId = Guid.valueOrUndefined(props.companyId);
    this.billingPlanId = Guid.valueOrUndefined(props.billingPlanId);
    if (props.plan) {
      this.plan = new AdminBillingPlan(props.plan);
    }
    this.modifiers = mapArray(
      props.modifiers,
      (x) => new AdminBillingSubscriptionModifier(x)
    );
    this.isActive = asBoolean(props.isActive);
    this.startedAt = utcDateOrUndefined(props.startedAt);
    this.endedAt = utcDateOrUndefined(props.endedAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new BillingSubscriptionProperties(props.properties);
  }

  getScheduleDisplay(short?: boolean) {
    const f = short ? 'do MMM yyyy' : 'iii do MMM yyyy hh:mm a';
    const timeZone = short ? '' : ` (${this.timeZoneId})`;

    return `${format(this.startedAt, f)} - ${this.endedAt ? format(this.endedAt, f) : 'Ongoing'}${timeZone}`;
  }

  get status(): BillingSubscriptionStatuses {
    if (this.isDateActive) {
      return BillingSubscriptionStatuses.Active;
    }
    if (this.isUpcoming) {
      return BillingSubscriptionStatuses.Upcoming;
    }
    if (this.isExpired) {
      return BillingSubscriptionStatuses.Expired;
    }

    return BillingSubscriptionStatuses.Unknown;
  }

  get isDateActive(): boolean {
    return (
      isAfter(new Date(), this.startedAt) &&
      (isNil(this.endedAt) || isBefore(new Date(), this.endedAt))
    );
  }

  get isUpcoming(): boolean {
    return isAfter(this.startedAt, new Date());
  }

  get isExpired(): boolean {
    return !isNil(this.endedAt) && isBefore(this.endedAt, new Date());
  }

  get modifiedFeatures(): BillingFeatureTypes[] {
    return this.modifiers.map((x) => x.featureType);
  }

  get allFeaturesModified(): boolean {
    return this.modifiers.length === Object.values(BillingFeatureTypes).length;
  }
}
