import { Guid } from '@komo-tech/core/models/Guid';
import { QueryParams } from '@komo-tech/core/models/QueryParams';
import { mapArray } from '@komo-tech/core/utils/array';
import { getDateForFileName } from '@komo-tech/core/utils/date';
import { ApiOrderedPagedRequest } from '@komo-tech/ui/api/types';
import { QueryClient } from '@tanstack/react-query';
import saveAs from 'file-saver';

import { ICompaniesFilter } from '@/admin/components/super-user-settings/Companies/Filter/CompaniesFilter';
import { AdminCache } from '@/admin/data/AdminCache';
import { AdministratorSimple } from '@/admin/models/AdministratorSimple';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';
import {
  AllowedUserRules as CompanyAllowedUserRules,
  Company
} from '@/common/models/companies/Company';
import { CompanyCreditUsage } from '@/common/models/companies/CompanyCreditUsage';
import { CompanyInternalLabels } from '@/common/models/companies/CompanyInternalLabels';
import { CompanyOptions } from '@/common/models/companies/CompanyOptions';
import { CompanyPlan } from '@/common/models/companies/CompanyPlan';
import { CompanyPlanUpdateApiRequest } from '@/common/models/companies/CompanyPlanUpdateApiRequest';
import { CompanyPricePlanOptions } from '@/common/models/companies/CompanyPricePlanOptions';

import { AdminLiveApi } from './AdminApi';

export interface CompanySearchQuery
  extends ApiOrderedPagedRequest,
    ICompaniesFilter {
  search?: string;
}

const baseCacheKey = 'Company';
const baseSearch = [baseCacheKey, 'Search'];

export class AdminCompanyService {
  public static baseCacheKey = baseCacheKey;

  public static invalidateAllQueriesAsync(queryClient: QueryClient) {
    return queryClient.invalidateQueries([baseCacheKey]);
  }

  public static cacheKeys = {
    baseSearch,
    company: (companyId?: Guid) => [baseCacheKey, companyId?.toString()],
    companyPlans: (companyId: Guid) => [
      baseCacheKey,
      'CompanyPricePlans',
      companyId
    ],
    companyOptions: (companyId?: Guid) => [
      baseCacheKey,
      'CompanyOptions',
      companyId?.toString()
    ],
    creditUsage: (companyId: Guid) => [
      baseCacheKey,
      'CompanyCreditUsage',
      companyId
    ],
    pricePlanOptions: (companyId: Guid) => [
      baseCacheKey,
      'CompanyPricePlanOptions',
      companyId.toString()
    ],
    search: (query: CompanySearchQuery) => [
      ...baseSearch,
      AdminCache.administratorId?.toString(),
      query || {}
    ],
    activeSubscription: (companyId: Guid) => [
      baseCacheKey,
      'ActiveSubscription',
      companyId
    ],
    csm: (companyId: Guid) => [baseCacheKey, 'CSM', companyId]
  };
  public static getAsync = async (companyId: Guid) =>
    new Company(await AdminLiveApi.getAsync(`companies/${companyId}`));

  public static getOptionsAsync = async (companyId: Guid) =>
    new CompanyOptions(
      await AdminLiveApi.getAsync(`companies/${companyId}/options`)
    );

  public static createAsync = async (request: {
    name: string;
    csmId: Guid | string;
    hubspotId?: string;
    internalLabels: CompanyInternalLabels;
    workspaceReportingTimeZoneId: string;
  }) =>
    new Company(
      await AdminLiveApi.postAsync<Company>(`companies`, {
        ...request
      })
    );

  public static updateAsync = async (request: {
    companyId: Guid | string;
    name: string;
    hubspotId?: string;
    csmId: Guid | string;
    plansEnabled?: boolean;
    createPageEnabled?: boolean;
    internalLabels: CompanyInternalLabels;
  }) => {
    const { companyId, ...body } = request;
    return new Company(
      await AdminLiveApi.putAsync<Company>(`companies/${companyId}`, body)
    );
  };

  public static getCreditUsageAsync = async (companyId: Guid) =>
    new CompanyCreditUsage(
      await AdminLiveApi.getAsync(`companies/${companyId}/credits/usage`)
    );

  public static searchAsync = ({
    take = 500,
    skip = 0,
    ...rest
  }: CompanySearchQuery) =>
    AdminLiveApi.getPageAsync<Company>(
      `companies${new QueryParams(rest).setTakeSkip(take, skip).toString()}`,
      (x) => new Company(x)
    );

  public static searchPlansAsync = async (companyId: Guid) => {
    return mapArray(
      (await AdminLiveApi.getAsync(
        `companies/${companyId}/pricing-plans`
      )) as any,
      (x) => new CompanyPlan(x)
    );
  };

  public static getPricePlanOptionsAsync = async (companyId: Guid) => {
    return new CompanyPricePlanOptions(
      await AdminLiveApi.getAsync(
        `companies/${companyId}/pricing-plans/options`
      )
    );
  };

  public static addPricePlanAsync = async (
    companyId: Guid,
    pricePlanId: Guid
  ) =>
    new CompanyPlan(
      await AdminLiveApi.postAsync(`companies/${companyId}/pricing-plans`, {
        pricePlanId
      })
    );

  public static updatePricePlanAsync = async (
    companyId: Guid,
    companyPricePlanId: Guid,
    request: CompanyPlanUpdateApiRequest
  ) =>
    new CompanyPlan(
      await AdminLiveApi.putAsync(
        `companies/${companyId}/pricing-plans/${companyPricePlanId}`,
        request
      )
    );

  public static deletePricePlanAsync = (
    companyId: Guid,
    companyPricePlanId: Guid
  ) =>
    AdminLiveApi.deleteAsync(
      `companies/${companyId}/pricing-plans/${companyPricePlanId}`
    );

  public static exportHubsAsync = (): Promise<any> =>
    AdminLiveApi.getAsync(`hubs/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-hubs.csv`)
    });

  public static exportMembersAsync = (): Promise<any> =>
    AdminLiveApi.getAsync(`members/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-members.csv`)
    });

  public static exportCompaniesAsync = (): Promise<any> =>
    AdminLiveApi.getAsync(`companies/export`, {
      responseHandler: async (res) =>
        saveAs(await res.blob(), `${getDateForFileName()}-companies.csv`)
    });

  public static updateMemberRulesAsync = async (
    companyId: Guid,
    request: CompanyAllowedUserRules
  ): Promise<Company> =>
    new Company(
      await AdminLiveApi.putAsync(`companies/${companyId}/member-rules`, {
        rules: request
      })
    );

  public static getActiveSubscriptionAsync = async (companyId: Guid) => {
    const subscription = await AdminLiveApi.getAsync(
      `companies/${companyId}/billing/subscriptions/active`
    );

    if (!subscription) {
      return null;
    }

    return new AdminBillingSubscription(subscription);
  };

  public static getCsmAdministratorAsync = async (companyId: Guid) => {
    const csm = await AdminLiveApi.getAsync(`companies/${companyId}/csm`);

    if (!csm) {
      return null;
    }

    return new AdministratorSimple(csm);
  };
}
