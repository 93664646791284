import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const RenameIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg className={tablerIconClasses(className)} ref={ref} {...rest}>
        <path d="M5 4h1a3 3 0 0 1 3 3 3 3 0 0 1 3-3h1" />
        <path d="M13 20h-1a3 3 0 0 1-3-3 3 3 0 0 1-3 3H5" />
        <path d="M5 16H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1" />
        <path d="M13 8h7a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-7" />
        <path d="M9 7v10" />
      </IconSvg>
    );
  }
);
