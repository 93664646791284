import { Guid } from '@komo-tech/core/models/Guid';
import { buildUrl } from '@komo-tech/core/utils/url';

import { AdminLiveApi } from '@/admin/data/AdminApi';
import { AddCsmsRequest } from '@/admin/data/Administrators/_types';
import { Administrator } from '@/admin/models/Administrator';
import { AdministratorRoles } from '@/admin/models/AdministratorRoles';
import { AdministratorSearch } from '@/admin/models/AdministratorSearch';

import { AdminAdministratorHooks } from './Administrators/_hooks';
import { AdministratorTypes } from '../models/AdministratorTypes';

export interface CheckEmailApiResponse {
  administratorId?: Guid;
  email?: string;
  isAdminVerified?: boolean;
  exists: boolean;
  error?: string;
}

export interface InviteAdminApiRequest {
  email: string;
  name: string;
  role: AdministratorRoles;
}

export interface InviteAdminApiResponse {
  email: string;
  emailSent: boolean;
  roleAdded: boolean;
}

interface UpdateAdministratorRequest {
  id: Guid;
  name: string;
  email: string;
  role?: AdministratorRoles;
  type?: AdministratorTypes;
}

export class AdminAdministratorService {
  public static hooks = AdminAdministratorHooks;
  public static cacheKeys = {
    admin: (id?: Guid) => ['admin', id?.toString()],
    search: () => ['admins-search'],
    searchCsm: () => ['admins-csm-search']
  };
  public static getAdministratorAsync = async (id: Guid) =>
    new Administrator(await AdminLiveApi.getAsync(`administrators/${id}`));

  public static getAdministratorFromSessionAsync = async (sessionId: Guid) =>
    new Administrator(
      await AdminLiveApi.getAsync(`administrators/sessions/${sessionId}`)
    );

  public static searchAdministratorsAsync = async () => {
    const response =
      await AdminLiveApi.getAsync<AdministratorSearch[]>('administrators');

    return response.map((x) => new AdministratorSearch(x));
  };

  public static updateAdministratorAsync = async ({
    id,
    ...request
  }: UpdateAdministratorRequest) => {
    return new Administrator(
      await AdminLiveApi.putAsync(`administrators/${id.toString()}`, request)
    );
  };

  public static deleteAdministratorAsync = async (id: Guid) => {
    return await AdminLiveApi.deleteAsync(`administrators/${id}`);
  };

  public static checkEmailAsync = (email: string) => {
    return AdminLiveApi.postAsync<CheckEmailApiResponse>(
      buildUrl('administrators/check-email', { email })
    );
  };

  public static inviteAsync = async (request: InviteAdminApiRequest) => {
    return await AdminLiveApi.postAsync<InviteAdminApiResponse>(
      'administrators/invite',
      request
    );
  };

  public static revokeInviteAsync = async (id: Guid) => {
    return AdminLiveApi.deleteAsync(`administrators/revoke/${id}`);
  };

  public static searchCsmAdministratorsAsync = async () => {
    const response = await AdminLiveApi.getAsync<AdministratorSearch[]>(
      'administrators/csms'
    );

    return response.map((x) => new AdministratorSearch(x));
  };

  public static addCsmAdministratorsAsync = async (request: AddCsmsRequest) => {
    const response = await AdminLiveApi.postAsync<Administrator[]>(
      'administrators/csms',
      request
    );

    return response.map((x) => new Administrator(x));
  };

  public static removeCsmAdministratorAsync = async (adminId: string | Guid) =>
    await AdminLiveApi.deleteAsync(`administrators/csms/${adminId}`);
}
