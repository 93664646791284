import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const UserSecurityIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M12.4061 1.18051C12.1476 1.06559 11.8524 1.06559 11.5939 1.18051L3.59386 4.73606C3.23273 4.89656 3 5.25468 3 5.64987V11C3 16.4648 6.72298 21.5805 11.7633 22.9392C11.9182 22.9809 12.0818 22.9809 12.2367 22.9392C17.277 21.5805 21 16.4648 21 11V5.64987C21 5.25468 20.7673 4.89656 20.4061 4.73606L12.4061 1.18051ZM11.5929 3.36145C11.852 3.24596 12.148 3.24596 12.4071 3.36145L18.4071 6.03574C18.7677 6.19647 19 6.55432 19 6.94912V11.22C19 12.5493 18.6943 13.897 18.1546 15.1482C17.9153 15.7029 17.2168 15.8492 16.6743 15.5836C15.0668 14.7966 13.0345 14.5 12 14.5C10.9655 14.5 8.93316 14.7966 7.32568 15.5836C6.78317 15.8492 6.0847 15.7029 5.84545 15.1482C5.30572 13.897 5 12.5493 5 11.22V6.94912C5 6.55432 5.23229 6.19647 5.59289 6.03574L11.5929 3.36145ZM12 6C11.0717 6 10.1815 6.36875 9.52513 7.02513C8.86875 7.6815 8.5 8.57174 8.5 9.5C8.5 10.4283 8.86875 11.3185 9.52513 11.9749C10.1815 12.6313 11.0717 13 12 13C12.9283 13 13.8185 12.6313 14.4749 11.9749C15.1313 11.3185 15.5 10.4283 15.5 9.5C15.5 8.57174 15.1313 7.6815 14.4749 7.02513C13.8185 6.36875 12.9283 6 12 6ZM12 8C12.3978 8 12.7794 8.15804 13.0607 8.43934C13.342 8.72064 13.5 9.10218 13.5 9.5C13.5 9.89782 13.342 10.2794 13.0607 10.5607C12.7794 10.842 12.3978 11 12 11C11.6022 11 11.2206 10.842 10.9393 10.5607C10.658 10.2794 10.5 9.89782 10.5 9.5C10.5 9.10218 10.658 8.72064 10.9393 8.43934C11.2206 8.15804 11.6022 8 12 8ZM12 16.5C13.1835 16.5 14.6512 16.8467 15.6833 17.33C16.1803 17.5628 16.256 18.1884 15.8757 18.584C14.8196 19.6829 13.5743 20.5196 12.2544 20.9271C12.0886 20.9782 11.9114 20.9782 11.7456 20.9271C10.4273 20.5201 9.18333 19.6849 8.12802 18.5879C7.74609 18.1909 7.82355 17.5624 8.32264 17.3292C9.35614 16.8463 10.8171 16.5 12 16.5Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
