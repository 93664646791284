import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const LeaderboardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fill={color || getDefaultColor()}
          d="M21 20.8C21 20.9105 20.9105 21 20.8 21H3.2C3.08954 21 3 20.9105 3 20.8V19.2C3 19.0895 3.08954 19 3.2 19H20.8C20.9105 19 21 19.0895 21 19.2V20.8ZM8 10.5C8 10.2239 7.77614 10 7.5 10H4.5C4.22386 10 4 10.2239 4 10.5V17H8V10.5ZM14 3.5C14 3.22386 13.7761 3 13.5 3H10.5C10.2239 3 10 3.22386 10 3.5V17H14V3.5ZM20 6.5C20 6.22386 19.7761 6 19.5 6H16.5C16.2239 6 16 6.22386 16 6.5V17H20V6.5Z"
        />
      </IconSvg>
    );
  }
);
