import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import {
  utcFormatDateTimeRange,
  utcFormatDateTimeRangeShort
} from '@komo-tech/core/utils/date';
import { asNumber } from '@komo-tech/core/utils/number';
import { asSingularOrPlural } from '@komo-tech/core/utils/string';
import addSeconds from 'date-fns/addSeconds';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import min from 'lodash/min';

import {
  getCreditsDaysUsed,
  getCreditsEndsAt,
  getCreditsFullEndsAt,
  getCreditsStartsAt,
  getCreditsTotalDays,
  wasCreditsTerminated
} from '@/common/utils/PlanFunctions';

import { CouponTrackingModel } from '../pricing/CouponTrackingModel';
import {
  FeatureTrackingModel,
  HasFeatureAccessResponse
} from '../pricing/FeatureTrackingModel';
import { PriceFeatures } from '../pricing/PriceFeatures';
import { PriceFeatureVariableTypes } from '../pricing/PriceFeatureTypes';
import { PricePlanProperties } from '../pricing/PricePlanProperties';
import { CompanyPriceCredit } from './CompanyPriceCredit';
import { CreditStatusModel, CreditStatusTypes } from './CreditStatusModel';

export class HubCampaignWithTrackingModel {
  id: Guid;
  planId: Guid;
  planName: string;
  planChipBackgroundColor: string;
  planChipTextColor: string;
  planChipLabel: string;
  planRank: number;
  hasUnPublishLock?: boolean;
  creditStatuses: CreditStatusModel[];
  features: FeatureTrackingModel[];
  coupons: CouponTrackingModel;

  get daysUsed() {
    return getCreditsDaysUsed(this.creditStatuses.map((x) => x.credit));
  }

  get startsAt() {
    return getCreditsStartsAt(this.creditStatuses.map((x) => x.credit));
  }

  get endsAt() {
    return getCreditsEndsAt(this.creditStatuses.map((x) => x.credit));
  }

  get activeOrUsedCreditEndsAt() {
    return getCreditsEndsAt(this.getActiveOrUsedCredits());
  }

  get fullEndsAt() {
    return getCreditsFullEndsAt(this.creditStatuses.map((x) => x.credit));
  }

  get wasTerminated() {
    return wasCreditsTerminated(this.creditStatuses.map((x) => x.credit));
  }

  get totalDays() {
    return getCreditsTotalDays(this.creditStatuses.map((x) => x.credit));
  }

  get daysUsedDisplay() {
    return `${this.daysUsed.toLocaleString()} of ${this.totalDays.toLocaleString()} days`;
  }

  get daysUsedPercent() {
    return min([(this.daysUsed / this.totalDays) * 100, 100]);
  }

  get creditsDisplay() {
    const count = this.creditStatuses.length;
    return `${count.toLocaleString()}x ${asSingularOrPlural('Credit', count)}`;
  }

  get dateRangeDisplay() {
    if (!this.startsAt) return '-';
    const date = utcFormatDateTimeRangeShort(this.startsAt, this.endsAt);
    if (isEqual(this.endsAt, this.fullEndsAt)) {
      return date;
    }

    return `${date} - Ended early ${utcFormatDateTimeRange(this.endsAt)}`;
  }

  get secondsRemainingOfActiveCredits() {
    return differenceInSeconds(
      getCreditsEndsAt(this.getActiveOrUsedCredits()),
      new Date()
    );
  }

  get secondsRemainingOfCurrentCredit() {
    return this.secondsRemaining % CompanyPriceCredit.DefaultSecondsDuration;
  }

  get endDateOfCurrentCreditFormatted() {
    const endDate = addSeconds(
      new Date(),
      this.secondsRemainingOfCurrentCredit
    );
    return format(endDate, 'dd MMM yyyy');
  }

  get secondsRemaining() {
    return differenceInSeconds(this.endsAt, new Date());
  }

  constructor(props: Partial<HubCampaignWithTrackingModel> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.planId = Guid.valueOrNew(props.planId);
    this.planRank = asNumber(props.planRank);
    if (!this.planChipBackgroundColor)
      this.planChipBackgroundColor =
        PricePlanProperties.DefaultChipBackgroundColor;
    if (!this.planChipTextColor)
      this.planChipTextColor = PricePlanProperties.DefaultChipTextColor;
    if (!this.planChipLabel) this.planChipLabel = this.planName;
    this.features = mapArray(
      props.features,
      (x) => new FeatureTrackingModel(x)
    );
    this.creditStatuses = mapArray(
      props.creditStatuses,
      (x) => new CreditStatusModel(x)
    );
    this.coupons = new CouponTrackingModel(props.coupons);
    this.hasUnPublishLock = asBoolean(props.hasUnPublishLock);
  }

  shouldShowStandardCouponsUsage() {
    const hasIncluded = this.coupons?.standard?.included > 0;
    const hasUsed = this.coupons?.standard?.used > 0;
    return hasIncluded || hasUsed;
  }

  shouldShowProCouponsUsage() {
    const hasIncluded = this.coupons?.pro?.included > 0;
    const hasUsed = this.coupons?.pro?.used > 0;
    return hasIncluded || hasUsed;
  }

  getFeaturesWithTracking() {
    this.features.filter((x) => x.type === PriceFeatureVariableTypes.Number);
  }

  hasNotStarted() {
    if (!this.startsAt) return true;
    return isAfter(this.startsAt, new Date());
  }

  getFeature(feature: PriceFeatures) {
    return this.features.find((x) => x.feature === feature);
  }

  hasAccessToFeature(feature: PriceFeatures, id?: Guid) {
    let response: HasFeatureAccessResponse = {
      hasAccess: false,
      idUsed: false
    };

    for (const x of this.features) {
      response = x.hasAccessToFeature(feature, id);
      if (response.hasAccess) {
        break;
      }
    }

    return response;
  }

  hasUnUsedCredits() {
    return this.getUnUsedCredits()?.length ? true : false;
  }

  getUnUsedCredits() {
    return this.creditStatuses
      .filter((x) => x.status === CreditStatusTypes.UnUsed)
      .map((x) => x.credit);
  }

  getActiveOrUsedCredits() {
    return this.creditStatuses
      .filter((x) => x.status !== CreditStatusTypes.UnUsed)
      .map((x) => x.credit);
  }
}
