import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const DoubleRefreshIcon = forwardRef<
  HTMLSpanElement,
  Omit<IconProps, 'svgProps'>
>(({ color, ...rest }: Omit<IconProps, 'svgProps'>, ref) => {
  return (
    <IconSvg
      {...rest}
      ref={ref}
      svgProps={{
        strokeWidth: 1.5,
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: color || getDefaultColor()
      }}
    >
      <path d="M21.168 8A10.003 10.003 0 0012 2C6.815 2 2.55 5.947 2.05 11" />
      <path d="M17 8h4.4a.6.6 0 00.6-.6V3M2.881 16c1.544 3.532 5.068 6 9.168 6 5.186 0 9.45-3.947 9.951-9" />
      <path d="M7.05 16h-4.4a.6.6 0 00-.6.6V21" />
    </IconSvg>
  );
});
