import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import isEqual from 'date-fns/isEqual';
import min from 'lodash/min';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';

import { CompanyPriceCredit } from '../models/companies/CompanyPriceCredit';

export const getCreditsDaysUsed = (credits: CompanyPriceCredit[]): number => {
  const startsAt = getCreditsStartsAt(credits);
  const endsAt = getCreditsEndsAt(credits);
  if (!startsAt || !endsAt) return 0;
  const difference = differenceInDays(new Date(), startsAt);
  return min([difference, differenceInDays(endsAt, startsAt)]);
};

export const getCreditsTotalDays = (credits: CompanyPriceCredit[]): number => {
  const startsAt = getCreditsStartsAt(credits);
  const endsAt = getCreditsEndsAt(credits);
  if (!startsAt || !endsAt) return 0;
  return differenceInDays(endsAt, startsAt);
};

export const getCreditsStartsAt = (
  credits: CompanyPriceCredit[]
): Date | undefined => {
  return sortBy(credits || [], (x) => x.startsAt)?.[0]?.startsAt;
};

export const getCreditsEndsAt = (
  credits: CompanyPriceCredit[]
): Date | undefined => {
  return orderBy(credits, (x) => x.endsAt, 'desc')?.[0]?.endsAt;
};

export const getCreditsFullEndsAt = (
  credits: CompanyPriceCredit[]
): Date | undefined => {
  const startsAt = getCreditsStartsAt(credits);
  if (!startsAt) return undefined;
  return addDays(startsAt, sum((credits || []).map((x) => x.daysDuration)));
};

export const wasCreditsTerminated = (
  credits: CompanyPriceCredit[]
): boolean => {
  const terminated = orderBy(credits, (x) => x.terminatedAt, 'desc')?.[0]
    ?.terminatedAt;
  if (!terminated) return false;
  return isEqual(terminated, getCreditsEndsAt(credits));
};
