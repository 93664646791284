/**
 * Need to add a new feature? check out apps/web/docs/entitlements.md for help
 */
export enum BillingFeatureTypes {
  Workspaces = 'Workspaces',
  Contacts = 'Contacts',
  Seats = 'Seats',
  Workflows = 'Workflows',
  CustomDomains = 'CustomDomains',
  Coupons = 'Coupons',
  LiveDataFeeds = 'LiveDataFeeds',
  RemoveKomoBranding = 'RemoveKomoBranding',
  ContentScheduling = 'ContentScheduling',
  Insights = 'Insights',
  ReportingDashboards = 'ReportingDashboards',
  EntryConditionScreen = 'EntryConditionScreen',
  CompetitionEntryRules = 'CompetitionEntryRules',
  CustomEmailDomains = 'CustomEmailDomains',
  CustomContactProperties = 'CustomContactProperties',
  ContactLists = 'ContactLists',
  CrmSync = 'CrmSync',
  IntegratedMarketResearch = 'IntegratedMarketResearch',
  Badges = 'Badges',
  AIReceiptValidation = 'AIReceiptValidation',
  VaultConnectedApp = 'VaultConnectedApp',
  TwilioConnectedApp = 'TwilioConnectedApp',
  ThuziConnectedApp = 'ThuziConnectedApp',
  SalesforceConnectedApp = 'SalesforceConnectedApp',
  SalesforceMarketingCloudConnectedApp = 'SalesforceMarketingCloudConnectedApp',
  TurnstileConnectedApp = 'TurnstileConnectedApp',
  FlowcodeConnectedApp = 'FlowcodeConnectedApp'
}

export enum BillingFeatureTypeCategories {
  General = 'General',
  Hubs = 'Hubs',
  Content = 'Content',
  Competitions = 'Competitions',
  Rewards = 'Rewards',
  CRM = 'CRM',
  Communications = 'Communications',
  Automation = 'Automation',
  AnalyticsAndReporting = 'AnalyticsAndReporting',
  Integrations = 'Integrations',
  ConnectedApps = 'ConnectedApps',
  SSO = 'SSO',
  Support = 'Support',
  Tools = 'Tools',
  SecurityAndCompliance = 'SecurityAndCompliance',
  Enterprise = 'Enterprise',
  DataRetention = 'DataRetention'
}
