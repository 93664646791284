export class BillingPlanProperties {
  description: string;
  chipColor: string;
  chipLabel: string;

  constructor(props?: Partial<BillingPlanProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
