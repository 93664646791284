import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const FileTextAltIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg className={tablerIconClasses(className)} ref={ref} {...rest}>
        <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
        <path d="M14 2v4a2 2 0 0 0 2 2h4" />
        <path d="M9 13v-1h6v1" />
        <path d="M12 12v6" />
        <path d="M11 18h2" />
      </IconSvg>
    );
  }
);
