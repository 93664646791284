import { Guid } from '@komo-tech/core/models/Guid';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { BillingFeatureTypeDefinition } from '@/common/models/billing/features/shared/BillingFeatureTypeDefinition';

import { BillingFeatureProperties } from '../shared/BillingFeatureProperties';
import { BillingFeatureTypes } from '../shared/BillingFeatureTypes';

export class AdminBillingFeature {
  id: Guid;
  billingPlanId: Guid;
  type: BillingFeatureTypes;
  value?: number;
  definition: BillingFeatureTypeDefinition;
  updatedAt: Date;
  properties: BillingFeatureProperties;

  constructor(props?: Partial<AdminBillingFeature>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.billingPlanId = Guid.valueOrUndefined(props.billingPlanId);
    this.definition = new BillingFeatureTypeDefinition(props.definition);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new BillingFeatureProperties(props.properties);
  }

  get isUnlimited() {
    return this.value === null;
  }
}
