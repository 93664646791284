export enum PriceFeatureVariableTypes {
  Flag = 'Flag',
  Number = 'Number'
}

export enum PriceFeatureTypes {
  Both = 'Both',
  PlanOnly = 'PlanOnly',
  PlanOnlyAlwaysIncluded = 'PlanOnlyAlwaysIncluded',
  AddOnOnly = 'AddOnOnly'
}
