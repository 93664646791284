export enum PriceFeatures {
  Engagements = 'Engagements',
  Entries = 'Entries',
  CardsAccessPro = 'CardsAccessPro',
  CardsPublishedStd = 'CardsPublishedStd',
  CardsPublishedPro = 'CardsPublishedPro',
  TagManagerIntegrations = 'TagManagerIntegrations',
  CustomFonts = 'CustomFonts',
  ContentScheduling = 'ContentScheduling',
  Sms = 'Sms',
  ContentEmbeds = 'ContentEmbeds',
  CustomDomain = 'CustomDomain',
  LiveTriviaBigScreens = 'LiveTriviaBigScreens',
  HubEntryConditions = 'HubEntryConditions',
  UserAccounts = 'UserAccounts',
  IntegrationsStandard = 'IntegrationsStandard',
  GatedCompetitions = 'GatedCompetitions',
  IntegrationsPro = 'IntegrationsPro',
  RemoveKomoBranding = 'RemoveKomoBranding',
  CouponsStandardAccess = 'CouponsStandardAccess',
  CouponsProAccess = 'CouponsProAccess'
}
