import { Guid } from '@komo-tech/core/models/Guid';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { BillingFeatureTypes } from '@/common/models/billing/features/shared/BillingFeatureTypes';

import { AdminBillingFeature } from '../../features/Admin/AdminBillingFeature';
import { AdminBillingSubscription } from '../../subscriptions/Admin/AdminBillingSubscription';
import { BillingPlanProperties } from '../shared/BillingPlanProperties';
import { BillingPlanStatuses } from '../shared/BillingPlanStatuses';

export class AdminBillingPlan {
  id: Guid;
  grandfatherId: Guid;
  name: string;
  status: BillingPlanStatuses;
  features: AdminBillingFeature[];
  subscriptions: AdminBillingSubscription[];

  subscriptionsCount: number;
  createdAt: Date;
  updatedAt: Date;
  properties: BillingPlanProperties;

  constructor(props?: Partial<AdminBillingPlan>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.grandfatherId = Guid.valueOrUndefined(props.grandfatherId);
    this.features = mapArray(props.features, (x) => new AdminBillingFeature(x));
    this.subscriptions = mapArray(
      props.subscriptions,
      (x) => new AdminBillingSubscription(x)
    );
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new BillingPlanProperties(props.properties);
  }

  get selectedFeatures(): BillingFeatureTypes[] {
    return this.features.map((x) => x.type);
  }

  get hasAllFeatures(): boolean {
    return this.features.length === Object.values(BillingFeatureTypes).length;
  }

  toOptionModel(): PlanOptionModel {
    return {
      label: this.displayName,
      value: this.id.toString(),
      plan: this
    };
  }

  get displayName(): string {
    return this.properties.chipLabel ?? this.name;
  }
}

export interface PlanOptionModel extends OptionModel {
  plan: AdminBillingPlan;
}
