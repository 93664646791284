import { PriceFeatureModel } from './PriceFeatureModel';
import { PricePlanPriceMatrix } from './PricePlanPriceMatrix';

export class PricePlanProperties {
  static DefaultChipBackgroundColor = '#F3CD32';
  static DefaultChipTextColor = '#000000';
  FeaturesJson: string;
  PriceMatrixJson: string;
  ChipLabel: string;
  ChipColor: string;
  ChipTextColor: string;
  constructor(props?: Partial<PricePlanProperties>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.FeaturesJson) this.FeaturesJson = '[]';
    if (!this.ChipColor) {
      this.ChipColor = PricePlanProperties.DefaultChipBackgroundColor;
    }

    if (!this.ChipTextColor) {
      this.ChipTextColor = PricePlanProperties.DefaultChipTextColor;
    }
  }

  getFeatures() {
    return PriceFeatureModel.listFromJson(this.FeaturesJson);
  }

  getPriceMatrix() {
    const fallback = new PricePlanPriceMatrix();
    try {
      const model = JSON.parse(this.PriceMatrixJson || '{}');
      return new PricePlanPriceMatrix(model);
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
}
