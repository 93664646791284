import { useAdminAddCsms } from './_useAddCsms';
import { useAdministratorGet } from './_useGet';
import { useAdminRemoveCsm } from './_useRemoveCsm';
import { useCsmAdministratorSearch } from './_useSearchCsms';

export const AdminAdministratorHooks = {
  useGet: useAdministratorGet,
  useSearchCsms: useCsmAdministratorSearch,
  useAddCsmsHandle: useAdminAddCsms.uncontrolled,
  useRemoveCsmHandle: useAdminRemoveCsm.uncontrolled,
  controlled: {
    useAddCsmsHandle: useAdminAddCsms.controlled,
    useRemoveCsmHandle: useAdminRemoveCsm.controlled
  }
};
