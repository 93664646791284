import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AdminAdministratorService } from '@/admin/data/AdminAdministratorService';

export const useCsmAdministratorSearch = () => {
  const query = useQuery(
    AdminAdministratorService.cacheKeys.searchCsm(),
    () => AdminAdministratorService.searchCsmAdministratorsAsync(),
    {
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const optionsData = useMemo(
    () =>
      (query.data || []).map((x) => ({
        label: x.name,
        value: x.id.toString()
      })),
    [query]
  );

  return {
    hasData: !!query?.data?.length,
    isLoading: query.isLoading,
    isError: query.isError,
    isFetching: query.isFetching,
    error: query.error,
    data: query.data,
    optionsData
  };
};
