export enum CompanyTypes {
  customer = 'customer',
  internal = 'internal'
}

export class CompanyInternalLabels {
  type: CompanyTypes;

  constructor(props: Partial<CompanyInternalLabels> = {}) {
    Object.assign(this, props);
  }
}
