import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { AdminCache } from '@/admin/data/AdminCache';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

import { AdminWorkspace } from '../workspaces/Admin/AdminWorkspace';
import { CompanyInternalLabels } from './CompanyInternalLabels';
import { CompanyPriceCredit } from './CompanyPriceCredit';

export class Company {
  id: Guid;
  name: string = '';
  plans: CompanyPlanSummary[];
  properties: CompanyProperties;
  workspaces: AdminWorkspace[];
  activeSubscription: AdminBillingSubscription;
  internalLabels: CompanyInternalLabels;
  createdAt: Date;
  updatedAt: Date;

  getDisplayName() {
    // Remove "[" from the start and "]" from the end of the string
    return this.name.replace(/^\[/, '').replace(/\]$/, '');
  }

  constructor(props: Partial<Company> = {}) {
    Object.assign(this, props);

    this.id = Guid.valueOrNew(props.id);
    this.plans = mapArray(props.plans, (x) => new CompanyPlanSummary(x));
    this.properties = new CompanyProperties(props.properties);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.workspaces = mapArray(props.workspaces, (x) => new AdminWorkspace(x));
    this.internalLabels = new CompanyInternalLabels(props.internalLabels);

    if (props.activeSubscription) {
      this.activeSubscription = new AdminBillingSubscription(
        props.activeSubscription
      );
    }
  }

  getDefaultWorkspace() {
    return (
      this.workspaces.find((x) => x.id.equals(AdminCache.workspaceId)) ??
      this.workspaces[0]
    );
  }
}

export class CompanyProperties {
  HubspotId: string;
  PlansEnabled: boolean;
  CreatePageEnabled: boolean;
  AllowedUserRules: string;
  CsmId: Guid;

  constructor(props: Partial<CompanyProperties> = {}) {
    Object.assign(this, props);
    this.PlansEnabled = asBoolean(props.PlansEnabled);
    this.CreatePageEnabled = asBoolean(props.CreatePageEnabled);
    this.CsmId = Guid.valueOrUndefined(props.CsmId);
  }

  getAllowedUserRules() {
    if (!this.AllowedUserRules) return new AllowedUserRules();
    try {
      return new AllowedUserRules(JSON.parse(this.AllowedUserRules));
    } catch {
      return new AllowedUserRules();
    }
  }
}

export class AllowedUserRules {
  allowedDomains: string[];
  allowedEmailAddresses: string[];
  bannedEmailAddresses: string[];

  isValidEmail(email: string) {
    if (this.allowedEmailAddresses.includes(email)) {
      return true;
    }
    if (this.bannedEmailAddresses.includes(email)) {
      return false;
    }
    if (!!this.allowedDomains.length) {
      const domain = email.split('@')[1];
      if (!this.allowedDomains.includes(domain)) {
        return false;
      }
    }
    return true;
  }

  constructor(props: Partial<AllowedUserRules> = {}) {
    this.allowedDomains = mapArray(props.allowedDomains, (x) =>
      x.toLowerCase()
    );
    this.allowedEmailAddresses = mapArray(props.allowedEmailAddresses, (x) =>
      x.toLowerCase()
    );
    this.bannedEmailAddresses = mapArray(props.bannedEmailAddresses, (x) =>
      x.toLowerCase()
    );
  }
}

export class CompanyPlanSummary {
  id: Guid;
  planId: Guid;
  planName: string;
  credits: CompanyPriceCredit[];
  updatedAt: Date;

  constructor(props: Partial<CompanyPlanSummary> = {}) {
    Object.assign(this, props);

    this.id = Guid.valueOrNew(props.id);
    this.planId = Guid.valueOrNew(props.planId);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.credits = mapArray(props.credits, (x) => new CompanyPriceCredit(x));
  }
}
