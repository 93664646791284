import { Guid } from '@komo-tech/core/models/Guid';
import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@komo-tech/ui/hooks/useActionHandler';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { AdminAdministratorService } from '@/admin/data/AdminAdministratorService';
import { Administrator } from '@/admin/models/Administrator';

export type RemoveCsmHookAction = (
  adminId: string | Guid,
  onSuccess?: () => void
) => Promise<any>;

interface HandleConfig extends UseHandleActionOptions<Administrator[]> {
  handler: AsyncActionHandler;
  queryClient: QueryClient;
}

const useControlled = (config: HandleConfig) => {
  return useCallback<RemoveCsmHookAction>(handleUpdateAsync(config), [
    config.queryClient
  ]);
};

const useUncontrolled = (options?: UseHandleActionOptions<Administrator[]>) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.queryClient = queryClient;
  controlledOptions.handler = handler;

  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleUpdateAsync = ({
  handler,
  queryClient,
  source = 'REMOVE_CSM',
  onSuccess: configOnSuccess,
  ...configRest
}: HandleConfig) => {
  return (adminId: string | Guid, onSuccess?: () => void) => {
    return handler(
      () => AdminAdministratorService.removeCsmAdministratorAsync(adminId),
      {
        source,
        throwOnError: false,
        noToastOnError: false,
        onSuccess: () => {
          queryClient.invalidateQueries(
            AdminAdministratorService.cacheKeys.searchCsm()
          );
          onSuccess?.();
          configOnSuccess?.();
        },
        ...configRest
      }
    );
  };
};

export const useAdminRemoveCsm = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
